import * as React from "react"
import { graphql } from "gatsby"

import Marquee from "react-fast-marquee"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Gil from "../assets/images/gilperez-portrait-full.jpg"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <div className="py-5 px-5 lg:px-0">
        <h1 className="CyGroteskGrandBold text-3xl my-5 lg:my-5">About</h1>
        <div className="grid grid-cols-1 md:grid-cols-6 xl:grid-cols-8 gap-5 md:gap-10">
          <div className="col-span-1 md:col-span-3 xl:col-span-6">
            <img src={Gil} className="w-full" />
            {/* <p className="text-sm mb-2 mt-4"><a href="/resume">Resume</a></p> */}
          </div>
          <div className="my-5 col-span-1 md:col-span-3 xl:col-span-2">
            <p className="mb-5 leading-6 text-md">Gil Perez. Currently, I serve as an Engineering Manager specializing in Generative AI and Emerging Technologies. My focus is on leveraging AI technologies to amplify brand narratives, drawing from over a decade of experience in the tech-driven creative space. With expertise in OpenAI, various AI models, and robust development frameworks, I have been pivotal in delivering innovative solutions for companies and clients.</p>
            {/* <p className="mb-5 leading-5 text-sm">During my role as a Director of Engineering professional specializing in eCommerce, software development, design, SEO, and Shopify, I have gained invaluable experience by working at prominent companies such as Warner Bros, WarnerMedia, and AT&T. Additionally, I have had the incredible opportunity to work closely with renowned celebrities like Kim Kardashian, Ellen DeGeneres, Kevin Hart, Karl Kani, and Kylie Jenner. Together, we've collaborated on exciting projects, creating bespoke eCommerce platforms, subscription-based applications, and video streaming services tailored to their unique requirements. I strive to continuously push boundaries and deliver exceptional results.</p> */}
            <p className="CyGroteskGrandBold text-sm mb-2 mt-4"><a href="https://www.linkedin.com/in/gildardoperez/" target="_blank">LinkedIn</a></p>
            <p className="CyGroteskGrandBold text-sm mb-2"><a href="/blog">Personal Blog</a></p>
            {/* <p className="CyGroteskGrandBold text-sm mb-2"><a href="https://github.com/gildardoperez" target="_blank">Github</a></p> */}
            {/* <p className="CyGroteskGrandBold text-sm mb-2"><a href="https://stackoverflow.com/users/1971505/gil-perez" target="_blank">StackOverflow</a></p> */}
            {/* <p className="CyGroteskGrandBold text-sm mb-2"><a href="https://www.bradient.com/" target="_blank">BRADIENT</a></p> */}
          </div>
        </div>

        <div className="grid gap-4 my-5 md:my-10">
        <div className="">
            <h2 className="CyGroteskGrandBold text-lg mb-5">Skills</h2>
              <Marquee autoFill direction='left' speed='10'>
                <div className="CyGroteskGrandMedium py-2 border-t-2 border-b-2 border-black">eCommerce Expert, Shopify Plus Partners, <a href="https://www.credly.com/badges/bd9d0c4d-6223-400e-b952-3c647d2c7dea" target="_blank" title="AWS Certified Cloud Practitioner">AWS Certified Cloud Practitioner</a>, Headless Commerce Solutions Architect, Headless CMS, Shopify Hydrogen, JavaScript, React, Gatsby, Node, React Native, Ruby on Rails, TailwindCSS, CSS3, UI/UX Prototyping Design and Technical SEO,&nbsp; </div>
              </Marquee>
              <Marquee autoFill direction='right' speed='10'>
                <div className="CyGroteskGrandMedium py-2 border-b-2 border-black">Director of Engineering, eCommerce Specialist, Software Development, ElevenLabs, Generative AI Text to Speech, Voice Cloning, Amazon Web Services Certified, Cloud Computing Certified, Klaviyo Ecommerce Marketing, Meta Business Partner, Cybersecurity Certified, Design, UI/UX Prototyping Design and Technical SEO,&nbsp; </div>
              </Marquee>
              <Marquee autoFill direction='left' speed='10'>
                <div className="CyGroteskGrandMedium py-2 border-b-2 border-black">Klaviyo Ecommerce Marketing, Meta Business Partner, Cybersecurity Certified, Design, Director of Engineering, eCommerce Specialist, Software Development, Creative Design, SEO Expert and Digital Marketing,&nbsp; </div>
              </Marquee>
              <Marquee autoFill direction='right' speed='10'>
                <div className="CyGroteskGrandMedium py-2 border-b-2 border-black">OpenAI, Open-Sourced Whisper, Headless Commerce Solutions Architect, Headless CMS, <a href="https://www.credly.com/badges/bd9d0c4d-6223-400e-b952-3c647d2c7dea" target="_blank" title="AWS Certified Cloud Practitioner">AWS Certified Cloud Practitioner</a>, Shopify Hydrogen, JavaScript, React, Gatsby, Node, React Native, Ruby on Rails, TailwindCSS, CSS3, Amazon Web Services Certified, Cloud Computing Certified,&nbsp; </div>
              </Marquee>
              <Marquee autoFill direction='left' speed='10'>
                <div className="CyGroteskGrandMedium py-2 border-b-2 border-black">eCommerce Expert, Shopify Plus Partners, Headless Commerce Solutions Architect, Amazon Web Services Certified, Cloud Computing Certified, Klaviyo Ecommerce Marketing, Meta Business Partner, Cybersecurity Certified, Design,&nbsp; </div>
              </Marquee>
              <Marquee autoFill direction='right' speed='10'>
                <div className="CyGroteskGrandMedium py-2 border-b-2 border-black">Shopify Hydrogen, JavaScript, React, Gatsby, Node, Large Language Model (LLM), React Native, Ruby on Rails, TailwindCSS, CSS3, Amazon Web Services Certified, Cloud Computing Certified, Klaviyo Ecommerce Marketing, Meta Business Partner, Cybersecurity Certified, Design, UI/UX Prototyping Design and Technical SEO,&nbsp; </div>
              </Marquee>
              <Marquee autoFill direction='left' speed='10'>
                <div className="CyGroteskGrandMedium py-2 border-b-2 border-black">Software Development, Meta Business Partner, Cybersecurity Certified, Design, <a href="https://www.credly.com/badges/bd9d0c4d-6223-400e-b952-3c647d2c7dea" target="_blank" title="AWS Certified Cloud Practitioner">AWS Certified Cloud Practitioner</a>, UI/UX Prototyping Design and Technical SEO, Director of Engineering, eCommerce Specialist, eCommerce Expert, Shopify Plus Partners, Headless Commerce Solutions Architect, Headless CMS, Shopify Hydrogen, JavaScript, <a href="https://soundcloud.com/gildardoperez" target="_blank" title="Music by Gildardo Perez">Music Production</a>, React, Gatsby, Node, React Native, Ruby on Rails, TailwindCSS, CSS3,&nbsp;</div>
              </Marquee>
              <Marquee autoFill direction='right' speed='10'>
                <div className="CyGroteskGrandMedium py-2 border-b-2 border-black">Amazon Web Services Certified, Cloud Computing Certified, Generative AI Text to Speech, Voice Cloning, Klaviyo Ecommerce Marketing, Meta Business Partner, Cybersecurity Certified, Director of Engineering, eCommerce Specialist, Software Development,&nbsp; </div>
              </Marquee>
          </div>
        </div>
        
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="About" />

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
